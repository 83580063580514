<template>
  <div class="templates-tab-container">
    <div class="template__head" @click="openTemplateTutorial">
      <span class="icon-info" v-tooltip="'Como crear un template'">
        <information-variant-icon/>
      </span>
      <p>{{ $t('templates.rules') }}</p>
    </div>
    <hcc-card
      class="template-card"
      bodyClass="template__body"
      v-for="template in templates"
      :key="template.id"
    >
      <div class="template" @click="showDetailModal(template)">
        <div class="template__description">
          <file-document-icon class="template__icon" />
          <div>
            <div>{{ template.element_name | truncate(20) }}</div>
            <div>
              <hcc-status-dot
                v-tooltip="template.status"
                :color="getColor(template.status)"
              />
              <span class="template__type">{{
                template.settings.message_type
              }}</span>
            </div>
          </div>
        </div>
        <hcc-button-icon v-if="!showDelete"
          @click.stop="deleteCard(template.id)"
          v-tooltip="$t('campaigns.data-bank.delete')"
        >
          <delete-icon class="template__icon" />
        </hcc-button-icon>
      </div>
    </hcc-card>
    <hcc-card class="template-card" bodyClass="template__body">
      <div class="template--upload">
        <button class="template--upload__button" @click="showAddModal()">
          <plus-circle-icon class="template--upload__icon" />
          <div>
            {{ $t("campaigns.data-bank.templates.add-template") }}
          </div>
        </button>
      </div>
    </hcc-card>
    <add-template-modal />
    <hcc-confirmation />
    <template-detail-modal :detail="template" @update="update" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import deleteTemplate from '@/graphql/mutations/dataBank/deleteTemplate.gql';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    HccStatusDot: () => import('@/components/shared/HccStatusDot/index.vue'),
    TemplateDetailModal: () => import('./Modals/TemplateDetailModal.vue'),
    AddTemplateModal: () => import('./Modals/AddTemplateModal.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      template: null,
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      templates: 'databank/getTemplates',
      user: 'shared/userInfo',
    }),
    showDelete() {
      return this.user.role === 'admin';
    },
  },
  methods: {
    ...mapActions({
      deleteTemp: 'databank/deleteTemplate',
    }),
    ...mapMutations('databank', ['updateTemplate']),
    showAddModal() {
      this.$modal.show('add-template-modal');
    },
    showDetailModal(props) {
      this.template = props;
      this.$modal.show('template-detail-modal');
    },
    deleteCard(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => {
          this.delete({ id: props });
        },
      });
    },
    async delete(data) {
      try {
        const res = await this.$apollo.mutate({
          mutation: deleteTemplate,
          variables: data,
        });
        this.deleteTemp(res.data.deleteTemplate);
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      }
    },
    async update(template) {
      this.updateTemplate(template);
    },
    getColor(status) {
      if (status.toLowerCase() === 'pending') {
        return 'standBy';
      }
      if (status.toLowerCase() === 'rejected') {
        return 'warning';
      }
      if (status.toLowerCase() === 'inactive') {
        return 'warning';
      }
      return 'active';
    },
    openTemplateTutorial() {
      window.open('https://blog.sellia.com.mx/reglas-de-whatsapp-y-proceso-de-aprobacion-de-templates/', '_blank', 'noopener, noreferrer');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_templates_tab_data_bank.scss";
</style>
